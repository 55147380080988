import React, { Component } from 'react';

class Table extends Component {

    render() { 
        if(this.props.Session){
            if(this.props.Session.Competitors){
                return ( 
                    <div className="App">
                        <table id ="competitors">
                            <tbody>
                                <tr>
                                    <th>Position</th>
                                    <th>Name</th>
                                    <th>Number</th>
                                    <th>Chassis</th>
                                    <th>Team</th>
                                    <th>BestLap</th>
                                    <th>LastLap</th>
                                    <th>Gap</th>
                                    <th>Diff</th>
                                    <th>Grid</th>
                                </tr>
                                {this.props.Session.Competitors.map((driver, index) => (
                                    <tr>
                                        <td>{driver.Position}</td>
                                        <td>{driver.CompetitorName}</td>
                                        <td>{driver.CompetitorNumber}</td>
                                        <td>{driver.Chassis || driver.Vehicle}</td>
                                        <td>{driver.TeamName}</td>
                                        <td>{this.props.msToTime(driver.BestLaptime*1000)}</td>
                                        <td>{this.props.msToTime(driver.LastLaptime*1000)}</td>
                                        <td>{driver.Behind}</td>
                                        <td>{driver.Gap}</td>
                                        <td>{driver.GridPosition}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );    
            } else {
                return ( 
                    <div className="App">
                    </div>
                );    
            }
        } else {
            return ( 
                <div className="App">
                </div>
            );    
        }
  
    }
}
export default Table ;
